<template>
    <painel-component>
        <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
            <h5 class="text-uppercase">Estoque</h5>
        </row-col-component>
        <div class="clearfix mb-3">
            <button-route title="Voltar" class="btn-default float-end mx-1" :items="itemsOne"/>
            <button-route title="Fazer Pedidos" class="btn-default float-end mx-1" :items="itemsOrder"/>
        </div>

        <form>
            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Número:</label>
                    <input-component v-model="number"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 mt-4">
                    <button-submit @click="submit" icon="fa-magnifying-glass"/>
                </row-col-component>
            </row-component>
        </form>

        <table class="table table-striped">
            <thead>
            <tr>
                <th scope="col" style="width: 45%">Número</th>
                <th scope="col" style="width: 45%">Status</th>
                <th scope="col" style="width: 10%">Ações</th>
            </tr>
            </thead>
            <tbody class="table-group-divider">
            <tr v-for="(data, index) in object.data" :key="index">
                <td data-label="Numero">{{data.number}}</td>
                <td data-label="Status">{{ data.status_name }}</td>
                <td data-label="Ações">
                    <button class="btn btn-secondary" @click="printUrl(data.printUrl)">
                        <i class="fa-solid fa-eye"></i>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>

        <button-pagination :pagination="object" :offset="3" @paginate="loadItems"/>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonPagination from "@/components/buttons/ButtonPagination";
import RowComponent from "@/components/rows/RowComponent";
import RowColComponent from "@/components/rows/RowColComponent";
import InputComponent from "@/components/forms/InputComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";

import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "StockClientOrders",
    components: {
        ButtonSubmit,
        InputComponent,
        RowColComponent,
        RowComponent,
        ButtonPagination,
        ButtonRoute,
        PainelComponent
    },

    props: {
        hash: {
            required: true
        },

        id: {
            required: true
        },
    },

    created() {
        this.loadItems()
    },

    data() {
        return {
            itemsOne: {
                route: `/hash/${this.hash}/stock-client`,
                btn: 'btn-primary',
                icon: 'fa-rotate-left',
            },

            itemsOrder: {
                name: 'Fazer Pedido',
                route: `/hash/${this.hash}/${this.id}/bath-request`,
                btn: 'btn-primary',
                icon: 'fa-check-to-slot',
            },

            number: '',
        }
    },

    methods: {
        submit() {
            this.loadItems(1)
            this.searchSubmit = true
        },

        loadItems(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch("loadStockClientGetOrders", {...this.params, page})
                    .then((r) => {
                        if (this.searchSubmit) {
                            this.searchSubmit = false
                            if (r.data.length === 0) {
                                toast.info('Nada foi encontrado com os parâmetros informados!', {timeout: 1500})
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                })
            }
        },

        printUrl(url){
            window.open(url, '_blank')
        }
    },

    computed: {
        params() {
            return {
                hash: this.hash,
                number: this.number
            }
        },

        object() {
            return this.$store.state.stock_client.items
        }
    }
}
</script>

<style scoped>
.btn-default {
    border-color: var(--primary-color-hexa);
    background-color: var(--primary-color-hexa);
}

.btn-default:active,
.btn-default:focus,
.btn-default:hover {
    border-color: var(--primary-color-hexa) !important;
    background-color: var(--primary-color-hexa) !important;
}
</style>