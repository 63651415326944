<template>
    <vue-easy-lightbox :visible="visibleRef" :imgs="formData.photosModal" :index="indexRef" @hide="onHide"/>

    <painel-component>
        <p>Bem vindo ao sistema, <strong>Ordem de serviço</strong></p>
        <hr>

        <span class="badge my-4 iwassa-bg">
            Stats: {{ statusOSString(formData.status) }}
        </span>

        <row-component>
            <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                <label>OS:</label>
                <input-component v-model="formData.os" :type="'text'" disabled/>
            </row-col-component>

            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <label>Data:</label>
                <input-component v-model="formData.date_hour_create" :type="'text'" disabled/>
            </row-col-component>
        </row-component>

        <row-component class="justify-content-between mb-3">
            <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                <h5 class="text-uppercase">Serviços</h5>
            </row-col-component>

            <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                <button-route title="Imprimir" class="float-end iwassa-bg mx-1" :href="formData.urlPrintOS"
                              target="_blank" :items="itemsPrint"/>
                <button-route title="Adicionar serviço por Grupo de Banho" class="float-end iwassa-bg mx-1"
                              @click="routeBathGroup" :items="itemsAdd"/>
            </row-col-component>
        </row-component>

        <row-component>
            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-3 mb-5"
                               v-for="(data, index) in formData.baths" :key="index">
                <div class="card position-relative" style="width: 100%;" :data-count="index + 1" :id="'card' + data.id">
                    <div class="btn-avatar-view">
                        <button-submit @click="showImg(index)" icon="fa-solid fa-camera-retro"/>
                    </div>
                    <img @click="showImg(index)" v-lazy="data.photo" class="img-client" alt="...">
                    <div class="card-body">
                        <h6 class="card-title">Grupo de banho: {{ data.name_group }}</h6>
                        <ul class="list-group list-group-flush mb-4">
                            <li class="list-group-item">Quantidade: {{ data.the_amount }}</li>
                            <li class="list-group-item">Peso unitário: {{ data.kilo }}</li>
                            <li class="list-group-item">Peso total: {{ data.kilo_total }}</li>
                            <li class="list-group-item">Valor bruto: {{ data.price_bruto }}</li>
                            <li class="list-group-item">
                                <span class="badge text-bg-warning" v-if="parseInt(data.color_info) === 1">
                                    <i class="fa-solid fa-warning"></i>
                                    Peça: {{ data.total_bath }} UN | Banho {{ data.total_service }} UN
                                </span>
                                <span class="badge text-bg-success" v-if="parseInt(data.color_info) === 2">
                                    <i class="fa-solid fa-check"></i>
                                    Peça: {{ data.total_bath }} UN | Banho {{ data.total_service }} UN
                                </span>
                            </li>
                        </ul>
                        <button-submit @click="routeAdd(data.id, data.bath_group_id)" name="Adicionar"
                                       icon="fa-circle-plus"/>
                    </div>
                </div>
            </row-col-component>
        </row-component>

        <div class="my-5">
            <button-submit @click="submit" name="Aprovar OS" icon="fa-solid fa-circle-check"/>
        </div>
    </painel-component>
</template>

<script>
import InputComponent from "@/components/forms/InputComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import RowComponent from "@/components/rows/RowComponent";
import RowColComponent from "@/components/rows/RowColComponent";
import PainelComponent from "@/components/dashboard/PainelComponent";
import VueEasyLightbox from 'vue-easy-lightbox'
import {useToast} from "vue-toastification";
import {ref} from "vue";

const toast = useToast();
export default {
    name: 'HomeView',
    components: {
        PainelComponent,
        RowColComponent,
        RowComponent,
        ButtonRoute,
        ButtonSubmit,
        InputComponent,
        VueEasyLightbox
    },

    props: {
        hash: {
            required: true,
        },
    },

    created() {
        this.loadItems()
    },

    setup() {
        const visibleRef = ref(false)
        const indexRef = ref(0)
        const imgs = []
        const showImg = (index) => {
            indexRef.value = index
            visibleRef.value = true
        }
        const onHide = () => visibleRef.value = false
        return {
            visibleRef,
            indexRef,
            imgs,
            showImg,
            onHide
        }
    },

    data() {
        return {
            id: '',

            itemsPrint: {
                route: '#',
                btn: 'btn-primary',
                icon: 'fa-print',
            },

            itemsAdd: {
                route: '/',
                btn: 'btn-primary',
                icon: 'fa-plus',
            },

            itemsHome: {
                route: '#',
                name: 'Voltar',
                btn: 'btn-secondary',
                icon: 'fa-rotate-left',
            },

            formData: {
                hash: this.hash,
                os: '',
                status: '',
                code_url: '',
                urlPrintOS: '',
                date_hour_create: '',
                baths: [],
                photosModal: []
            },
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadOs', this.hash)
                .then((r) => {
                    this.formData = r
                    this.id = r.id
                    this.loadCard()
                }).catch(() => {
                toast.error('Erro desconhecido!', {timeout: 1500})
            })
        },

        submit() {
            this.$store.dispatch('ToApproveOs', this.formData)
                .then(() => {
                    toast.success('OS Aprovada com sucesso', {timeout: 1500})
                }).catch(() => {
                toast.error('Erro ao aprovar a OS', {timeout: 1500})
            })
        },

        routeAdd(id, id_bath) {
            let data = {
                id: id,
                bath: id_bath,
                hash: this.formData.code_url
            }
            this.$router.push({name: "os-bath-create", params: {id: data.id, id_bath: data.bath, hash: data.hash}})
        },

        routeBathGroup() {
            this.$router.push({name: "bath-group-create", params: {hash: this.hash, id: this.id}})
        },

        loadCard() {
            if (this.idCard !== null)
                setTimeout(() => {
                    window.location.href = `#card${this.idCard}`
                    this.$store.commit("STORAGE", null)
                }, 1000)
        },

        statusOSString(value) {
            switch (parseInt(value)) {
                case  1:
                    return "Cadastrado"
                case  2:
                    return "Enviado Para Cliente"
                case  3:
                    return "Aguardando Aprovação Iwassa"
                case  4:
                    return "Separação"
                case  5:
                    return "Amarração"
                case  6:
                    return "Banho"
                case  7:
                    return "Inspeção"
                case  8:
                    return "Retornou para banho"
                case  9:
                    return "Envio"
                case  10:
                    return "Finalizado"
                default:
                    break;
            }
        }
    },

    computed: {
        idCard() {
            return this.$store.state.var.storage
        }
    }
}
</script>

<style scoped>
.img-client {
    display: block;
    margin-right: auto;
    margin-left: auto;
    width: auto;
    max-height: 200px;
}

.iwassa-bg {
    border-color: var(--primary-color-hexa);
    background-color: var(--primary-color-hexa);
}

.iwassa-bg:active,
.iwassa-bg:focus,
.iwassa-bg:hover {
    border-color: var(--primary-color-hexa) !important;
    background-color: var(--primary-color-hexa) !important;
}

.btn-avatar-view {
    margin: 0;
    opacity: 0;
    width: 150px;
    z-index: 100;
    height: 200px;
    display: grid;
    position: absolute;
    place-items: center;
    left: 50%;
    top: 100px;
    transform: translate(-50%, -50%);
    transition: all 0.2s ease-in-out;
    background-color: rgba(0, 0, 0, 0.3);
}

.card {
    border-radius: 0 0.375rem 0.375rem 0.375rem;
}

.card:before {
    top: -40px;
    left: -1px;
    height: 40px;
    width: 100px;
    padding: 0.5rem;
    color: #FFFFFF;
    font-weight: bold;
    position: absolute;
    background: var(--primary-color-hexa);
    content: attr(data-count);
    border: 1px solid var(--primary-color-hexa);
    border-radius: 0.375rem 0.375rem 0 0;
}

.btn-avatar-view:hover {
    opacity: 1;
}
</style>
