import axios from "axios";
import {URL_BASE} from "@/configs/constants";

const RESOURCE = "client/hash";

export default {
    actions: {

        loadStockClientOrderAdd(context, hash) {
            context.commit("LOADER", true)
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}${RESOURCE}/${hash}/stock-client-get`)
                    .then((response) => {
                        return resolve(response.data)
                    }).catch((errors) => {
                    return reject(errors)
                }).finally(() => context.commit("LOADER", false))
            })
        },

        storeStockClientOrderAdd(context, formData) {
            context.commit("LOADER", true)
            return new Promise((resolve, reject) => {
                axios.post(`${URL_BASE}${RESOURCE}/${formData.hash}/stock-client-order-add`, formData)
                    .then((response) => {
                        return resolve(response.data)
                    }).catch((errors) => {
                    return reject(errors)
                }).finally(() => context.commit("LOADER", false))
            })
        },

    }
}