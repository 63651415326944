export default {
    STORAGE(state, name){
        state.storage = name
    },

    LOAD_MULTISELECT_SERVICE(state, name) {
        state.multiselectService = name
    },

    LOAD_MULTISELECT_BASE(state, name) {
        state.multiselectServiceBase = name
    },

    LOAD_MULTISELECT_COLOR(state, name) {
        state.multiselectServiceColor = name
    },

    LOAD_MULTISELECT_VARNISH(state, name) {
        state.multiselectServiceVarnish = name
    },

    LOAD_MULTISELECT_BATH_GROUP(state, name) {
        state.multiselectServiceBathGroup = name
    },
}