<template>
    <painel-component>
        <error-component :errors="errors" v-if="showError"/>
        <form>
            <div class="clearfix d-flex my-4">
                <span class="badge text-bg-success">Peças disponíveis para banho: {{ formData.parts }}</span>
            </div>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>OS:</label>
                    <input-component v-model="os" :type="'text'" disabled/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                    <label>Serviço:</label>
                    <Multiselect v-model="formData.service_id" :options="multiselectService" :searchable="true"
                                 @select="loadServicePrice(formData.service_id)"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Base:</label>
                    <Multiselect v-model="formData.base_id" :options="multiselectServiceBase" :searchable="true"
                                 @select="loadServicePriceAdditional(formData.base_id, 1)"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Cor:</label>
                    <Multiselect v-model="formData.cor_id" :options="multiselectServiceColor" :searchable="true"
                                 @select="loadServicePriceAdditional(formData.cor_id, 2)"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Verniz:</label>
                    <Multiselect v-model="formData.verniz_id" :options="multiselectServiceVarnish" :searchable="true"
                                 @select="loadServicePriceAdditional(formData.verniz_id, 3)"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                    <label>Quantidade Original:</label>
                    <input-component v-model="formData.the_amount" :type="'number'" disabled/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                    <label>Quantidade Banho:</label>
                    <input-component @keyup="calcAll(); sumSubTotal();"
                                     v-model="formData.the_amount" :type="'number'"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                    <label>Peso:</label>
                    <input-component v-model="formData.kilo" :type="'text'" disabled/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                    <label>Total Peso:</label>
                    <input-component v-model="formData.kilo_total" :type="'text'" disabled/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                    <label>Milésimos:</label>
                    <input-component maxlength="4" @keyup="calcAll" v-money="money3" v-model="formData.layer"
                                     :type="'text'"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                    <label>M.O.:</label>
                    <input-component maxlength="4" @keyup="calcAll" disabled v-money="money3"
                                     v-model="formData.thousandth" :type="'text'"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                    <label>Preço Base:</label>
                    <input-component maxlength="12" v-money="money" @keyup="sumBase(); calcAll();"
                                     v-model="formData.price_base" :type="'text'" disabled/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                    <label>Preço Total Base:</label>
                    <input-component maxlength="12" v-money="money" v-model="formData.price_base_total" :type="'text'"
                                     disabled/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                    <label>Preço Cor:</label>
                    <input-component maxlength="12" v-money="money" @keyup="sumColor(); calcAll()"
                                     v-model="formData.price_cor" :type="'text'" disabled/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                    <label>Preço Total Cor:</label>
                    <input-component maxlength="12" v-money="money" v-model="formData.price_cor_total" :type="'text'"
                                     disabled/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                    <label>Preço Verniz:</label>
                    <input-component maxlength="12" v-money="money" @keyup="sumVerniz(); calcAll()"
                                     v-model="formData.price_verniz" :type="'text'" disabled/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                    <label>Preço Total Verniz:</label>
                    <input-component maxlength="12" v-money="money" v-model="formData.price_verniz_total" :type="'text'"
                                     disabled/>
                </row-col-component>
            </row-component>

            <row-component>
                <hr>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Preço Serviço:</label>
                    <input-component maxlength="12" v-money="money" @keyup="calcAll();" v-model="formData.price_kilo"
                                     :type="'text'" disabled/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Custo Banho por KG:</label>
                    <input-component v-model="formData.price_bath_kg" :type="'text'" disabled/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Custo Serviço (ML + MO):</label>
                    <input-component maxlength="12" v-money="money" v-model="formData.price_ml_mo" :type="'text'"
                                     disabled/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Subtotal:</label>
                    <input-component maxlength="12" v-money="money" v-model="formData.price_subtotal" :type="'text'"
                                     disabled/>
                </row-col-component>
                <hr>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit @submit="submit" class="w-100" :disabled="sendoForm" name="Salvar"
                                   icon="fa-solid fa-floppy-disk"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsRoute"/>
                </row-col-component>
            </row-component>
        </form>

        <div class="my-3" v-if="services.length > 0">
            <label>Banhos Adicionados:</label>
        </div>

        <div v-for="(dataTwo, indexTwo) in services" :key="indexTwo">
            <div v-if="parseInt(formData.id) !== parseInt(dataTwo.id)" class="bg-body-secondary p-2 mb-3">
                <div class="d-flex justify-content-between align-items-center">
                    <div>
                        <strong>Serviço: {{ dataTwo.name_service }}</strong>
                    </div>

                    <div>
                        <router-link class="btn btn-success" @click="loadAll"
                                     :to="{name:'os-bath-edit', params: {hash: this.hash, id: this.id, id_service: dataTwo.id} }">
                            <i class="fa-solid fa-pen-to-square"></i>
                        </router-link>
                        <!--                        <button-delete type="button" @click="destroyBathService(dataTwo)"/>-->
                    </div>
                </div>

                <hr>
                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th scope="col" style="width: 15%">Base</th>
                        <th scope="col" style="width: 15%">Preço</th>
                        <th scope="col" style="width: 15%">Cor</th>
                        <th scope="col" style="width: 15%">Preço</th>
                        <th scope="col" style="width: 15%">Verniz</th>
                        <th scope="col" style="width: 10%">Preço</th>
                    </tr>
                    </thead>
                    <tbody class="table-group-divider">
                    <tr>
                        <td data-label="Base">{{ dataTwo.name_base }}</td>
                        <td data-label="Preço">R$ {{ dataTwo.price_base }}</td>
                        <td data-label="Cor">{{ dataTwo.name_cor }}</td>
                        <td data-label="Preço">R$ {{ dataTwo.price_cor }}</td>
                        <td data-label="Verniz">{{ dataTwo.name_verniz }}</td>
                        <td data-label="Preço">R$ {{ dataTwo.price_verniz }}</td>
                    </tr>
                    </tbody>
                </table>

                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th scope="col" style="width: 20%">Quantidade</th>
                        <th scope="col" style="width: 20%">Peso</th>
                        <th scope="col" style="width: 20%">Total Peso</th>
                        <th scope="col" style="width: 20%">Milésimos</th>
                        <th scope="col" style="width: 20%">M.O.</th>
                    </tr>
                    </thead>
                    <tbody class="table-group-divider">
                    <tr>
                        <td data-label="Quantidade">{{ dataTwo.the_amount }}</td>
                        <td data-label="Peso">{{ dataTwo.kilo }}</td>
                        <td data-label="Total Peso">{{ dataTwo.kilo_total }}</td>
                        <td data-label="Milésimos">{{ dataTwo.layer }}</td>
                        <td data-label="M.O.">{{ dataTwo.thousandth }}</td>
                    </tr>
                    </tbody>
                </table>

                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th scope="col" style="width: 20%">Preço do Serviço</th>
                        <th scope="col" style="width: 20%">Custo do Banho por KG</th>
                        <th scope="col" style="width: 30%">
                            Custo do Serviço (MO + ML)
                        </th>
                        <th scope="col" style="width: 10%">Subtotal</th>
                    </tr>
                    </thead>
                    <tbody class="table-group-divider">
                    <tr>
                        <td data-label="Preço do Serviço">{{ dataTwo.price_kilo }}</td>
                        <td data-label="Custo do Banho por KG">
                            {{ dataTwo.price_bath_kg }}
                        </td>
                        <td data-label="Custo do Serviço (MO + ML)">
                            {{ dataTwo.price_ml_mo }}
                        </td>
                        <td data-label="Subtotal">{{ dataTwo.price_subtotal }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </painel-component>
</template>

<script>

import PainelComponent from "@/components/dashboard/PainelComponent";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import RowComponent from "@/components/rows/RowComponent";
import RowColComponent from "@/components/rows/RowColComponent";
import InputComponent from "@/components/forms/InputComponent";
import Multiselect from "@vueform/multiselect";

import {VMoney} from "v-money";
import {MONEY} from "@/configs/constants";
import {useToast} from "vue-toastification";
import ButtonSubmit from "@/components/buttons/ButtonSubmit.vue";
import ButtonRoute from "@/components/buttons/ButtonRoute.vue";

const toast = useToast()
export default {
    name: 'OSUpdateBathService',
    components: {
        ButtonRoute,
        ButtonSubmit,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        ErrorComponent,
        PainelComponent
    },

    directives: {
        money: VMoney
    },

    props: {
        hash: {
            required: true
        },

        id: {
            required: true
        },

        id_service: {
            required: true
        },
    },

    created() {
        this.loadOs()
    },

    data() {
        return {
            itemsRoute: {
                name: 'Voltar',
                route: `/hash/${this.hash}`,
                icon: 'fa-solid fa-rotate-left'
            },

            formData: {
                id: '',
                order_service_bath_id: '',
                service_id: '',
                creator_user_id: '',
                creator_user_name: '',
                type_user: '',
                kilo: '',
                kilo_total: '',
                the_amount: '',
                thousandth: '',
                layer: '',
                price_kilo: '',
                price_result: '',
                price_cost: '',
                price_fixo: '',
                base_id: '',
                cor_id: '',
                verniz_id: '',
                price_base: '',
                price_base_total: '',
                price_cor: '',
                price_cor_total: '',
                price_verniz: '',
                price_verniz_total: '',
                price_bath_kg: '',
                price_ml_mo: '',
                price_subtotal: '',
                price_bruto: '',
                the_amount_broked: '',
                the_amount_missing_stones: '',
                kilo_of_bath: '',
                miss: '',
                name_base: ''
            },

            show_ml: false,

            money: MONEY,
            money3: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '',
                precision: 1,
                allowBlank: false,
                max: 99,
                minimumNumberOfCharacters: 3,
                masked: true
            },

            os: '',

            services: [],

            errors: [],
            sendoForm: false,
            showError: false
        }
    },

    methods: {
        loadOs() {
            this.$store.dispatch('loadOSBath', this.$props)
                .then((r) => {
                    this.os = r.os
                    this.services = r.baths[0].services
                    this.formData = r.bathEdit
                    this.formData.parts = r.parts
                    this.$store.commit("STORAGE", this.id)
                    this.$store.dispatch('loadMultiselectService', this.hash)
                    this.$store.dispatch('loadMultiselectServiceBase', this.hash)
                    this.$store.dispatch('loadMultiselectServiceColor', this.hash)
                    this.$store.dispatch('loadMultiselectServiceVarnish', this.hash)
                })
        },

        loadAll() {
            this.$store.dispatch('loadMultiselectService', this.hash)
            this.$store.dispatch('loadMultiselectServiceBase', this.hash)
            this.$store.dispatch('loadMultiselectServiceColor', this.hash)
            this.$store.dispatch('loadMultiselectServiceVarnish', this.hash)
            this.$store.dispatch('loadOSBath', this.$props)
                .then((r) => {
                    this.services = r.baths[0].services
                })
            this.loadOs()
        },

        submit() {
            this.formData.hash = this.hash
            this.formData.price_result = 0
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('updateOsBath', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success('Cadastro atualizado com sucesso', {timeout: 1500})
                    }).catch((e) => {
                    this.showError = true
                    this.sendForm = false
                    this.errors = e.response.data.errors
                })
            }
        },

        loadServicePrice(id) {
            let params = {hash: this.hash, id: id}
            this.$store.dispatch('loadMultiselectServicePrice', params)
                .then((r) => {
                    this.formData.price_kilo = r.price
                    this.formData.thousandth = r.mo_value
                    this.formData.layer = r.ml_value
                    if (parseInt(r.show_ml) === 2) this.show_ml = false
                    if (parseInt(r.show_ml) === 1) this.show_ml = true
                })
            setTimeout(() => {
                this.calcAll()
            }, 1000)
        },

        loadServicePriceAdditional(id, type) {
            let params = {hash: this.hash, id: id}
            this.$store.dispatch('loadMultiselectServicePriceAdditional', params)
                .then((r) => {
                    if (type === 1) {
                        this.formData.price_base = r
                        this.sumBase()
                        this.calcAll()
                    }
                    if (type === 2) {
                        this.formData.price_cor = r
                        this.sumColor()
                        this.calcAll()
                    }
                    if (type === 3) {
                        this.formData.price_verniz = r
                        this.sumVerniz()
                        this.calcAll()
                    }
                })
        },

        // Calcular todos
        calcAll() {
            this.sumTotal()
            this.sumTotalKG()
            this.sumService()
            this.sumSubTotal()
            this.sumBase()
            this.sumColor()
            this.sumVerniz()
        },

        // Total Peso
        sumTotal() {
            let n1 = this.formData.kilo.replace(/[.,]/g, '')
            let n2 = n1 * this.formData.the_amount

            if (parseFloat(this.formData.price_base) > 0)
                this.sumBase()

            if (parseFloat(this.formData.price_cor) > 0)
                this.sumColor()

            if (parseFloat(this.formData.price_verniz) > 0)
                this.sumVerniz()

            return this.formData.kilo_total = this.kilo(this.pad(n2, 7))
        },

        // Calcular serviços adicionais
        sumBase() {
            let n1 = this.formData.price_base
            let n2 = this.formData.kilo_total
            let result = ''

            if (n1.length < 8) {
                result = parseFloat(n1.replace(',', '.')) * parseFloat(n2.replace(',', '')) / 1000;
            } else {
                result = parseFloat(n1.replace(',', '')) * parseFloat(n2.replace(',', ''))
            }

            result = result.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });

            return this.formData.price_base_total = result
        },

        sumColor() {
            let n1 = this.formData.price_cor
            let n2 = this.formData.kilo_total
            let result = ''

            if (n1.length < 8) {
                result = parseFloat(n1.replace(',', '.')) * parseFloat(n2.replace(',', '')) / 1000;
            } else {
                result = parseFloat(n1.replace(',', '')) * parseFloat(n2.replace(',', ''))
            }

            result = result.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });

            return this.formData.price_cor_total = result
        },

        sumVerniz() {
            let n1 = this.formData.price_verniz
            let n2 = this.formData.kilo_total
            let result = ''

            if (n1.length < 8) {
                result = parseFloat(n1.replace(',', '.')) * parseFloat(n2.replace(',', '')) / 1000;
            } else {
                result = parseFloat(n1.replace(',', '')) * parseFloat(n2.replace(',', ''))
            }

            result = result.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });

            return this.formData.price_verniz_total = result
        },

        // Calcular custo banho por KG
        sumTotalKG() {
            let mo = this.formData.thousandth.replace(',', '.')
            let ml = this.formData.layer.replace(',', '.')
            let kg = this.formData.price_kilo.replace(/[.,]/g, '')

            if (parseFloat(mo) > 0 || parseFloat(ml) > 0) {
                let n1 = parseFloat(mo) + parseFloat(ml)
                return this.formData.price_bath_kg = ((kg * n1) / 100).toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });
            } else {
                return this.formData.price_bath_kg = (kg / 100).toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });
            }
        },

        // Calcular custo serviço (ML + MO):
        sumService() {
            let price_kg_real = this.formData.price_bath_kg;
            let price_kg;
            let price_kg_real_formact = price_kg_real.replace(/[.,]/g, '');

            if (parseInt(price_kg_real_formact) > parseInt('99999')) {
                price_kg = parseFloat(price_kg_real.replace(',', ''));
            } else {
                price_kg = parseFloat(price_kg_real.replace(',', '.')) / 1000;
            }

            let n1 = this.formData.kilo_total
            let total_kg;
            if (n1.length < 8) {
                total_kg = parseFloat(n1.replace(',', '.'));
            } else {
                total_kg = parseFloat(n1.replace(',', ''))
            }

            let result = (price_kg * total_kg)
            return this.formData.price_ml_mo = result.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        },

        // Calcular subtotal
        sumSubTotal() {
            let n1 = parseFloat(this.formData.price_ml_mo.replace(/\./g, '').replace(',', '.'))
            let n2 = parseFloat(this.formData.price_base_total.replace(/\./g, '').replace(',', '.'))
            let n3 = parseFloat(this.formData.price_cor_total.replace(/\./g, '').replace(',', '.'))
            let n4 = parseFloat(this.formData.price_verniz_total.replace(/\./g, '').replace(',', '.'))
            let value = n1 + (n2 + n3 + n4)

            return this.formData.price_subtotal = value.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        },

        pad(str, max) {
            str = str.toString();
            str = str.length < max ? this.pad("0" + str, max) : str; // zero à esquerda
            str = str.length > max ? str.substring(0, max) : str; // máximo de caracteres
            return str;
        },

        kilo(v) {
            v = v.replace(/(\d{2})(\d{1,2})$/, "$1.$2") //Coloca um hífen entre o terceiro e o quarto dígitos
            v = v.replace(/(\d{2})(\d)/, "$1,$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
            return v
        },
    },

    beforeUpdate() {
        this.loadAll()
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        multiselectService() {
            return this.$store.state.var.multiselectService
        },

        multiselectServiceBase() {
            return this.$store.state.var.multiselectServiceBase
        },

        multiselectServiceColor() {
            return this.$store.state.var.multiselectServiceColor
        },

        multiselectServiceVarnish() {
            return this.$store.state.var.multiselectServiceVarnish
        },

    }
}
</script>