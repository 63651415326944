import axios from "axios";
import {URL_BASE} from "@/configs/constants";

const RESOURCE = "client/hash";

export default {

    loadMultiselectService(context, hash) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/${hash}/get-services`)
                .then((response) => {
                    context.commit("LOAD_MULTISELECT_SERVICE", response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadMultiselectServicePrice(context, formData) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/${formData.hash}/get-service-price/${formData.id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadMultiselectServiceBase(context, hash) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/${hash}/get-services-add/1`)
                .then((response) => {
                    context.commit("LOAD_MULTISELECT_BASE", response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadMultiselectServiceColor(context, hash) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/${hash}/get-services-add/2`)
                .then((response) => {
                    context.commit("LOAD_MULTISELECT_COLOR", response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadMultiselectServiceVarnish(context, hash) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/${hash}/get-services-add/3`)
                .then((response) => {
                    context.commit("LOAD_MULTISELECT_VARNISH", response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadMultiselectServicePriceAdditional(context, formData) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/${formData.hash}/get-service-add-price/${formData.id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadMultiselectServiceBathGroup(context, hash) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/${hash}/get-bath-group`)
                .then((response) => {
                    context.commit("LOAD_MULTISELECT_BATH_GROUP", response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },
}