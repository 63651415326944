import {createStore} from 'vuex'

import BathRequest from "@/store/modules/bath-request";
import BathGroup from "@/store/modules/bath-group";
import Loader from "@/store/modules/loader"
import OrderService from "@/store/modules/order-service";
import OsBath from "@/store/modules/os-bath";
import Settings from "@/store/modules/settings";
import StockClient from "@/store/modules/stock-client";
import Var from "@/store/modules/var";

export default createStore({
    modules: {
        bath_request: BathRequest,
        bath_group: BathGroup,
        loader: Loader,
        order_service: OrderService,
        os_bath: OsBath,
        settings: Settings,
        stock_client: StockClient,
        var: Var
    }
})
