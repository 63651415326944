import axios from "axios";
import {URL_BASE} from "@/configs/constants";

const RESOURCE = "client/hash";

export default {
    actions: {

        loadGetGroupsBaths(context, hash) {
            context.commit("LOADER", true)
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}${RESOURCE}/${hash}/get-os-add-service-group`)
                    .then((response) => {
                        return resolve(response.data)
                    }).catch((errors) => {
                    return reject(errors)
                }).finally(() => context.commit("LOADER", false))
            })
        },

        loadGroupsBaths(context, formData) {
            context.commit("LOADER", true)
            return new Promise((resolve, reject) => {
                axios.post(`${URL_BASE}${RESOURCE}/${formData.hash}/get-os-add-service-group-add`, formData)
                    .then((response) => {
                        return resolve(response.data)
                    }).catch((errors) => {
                    return reject(errors)
                }).finally(() => context.commit("LOADER", false))
            })
        },

        storeGroupBath(context, formData) {
            context.commit("LOADER", true)
            return new Promise((resolve, reject) => {
                axios.post(`${URL_BASE}${RESOURCE}/${formData.hash}/get-os-add-service-group-add-store`, formData)
                    .then((response) => {
                        return resolve(response.data)
                    }).catch((errors) => {
                    return reject(errors)
                }).finally(() => context.commit("LOADER", false))
            })
        }
    }
}