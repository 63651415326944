<template>
    <header>
        <div class="container">
            <img class="img-fluid" :src="settings.logo_white" alt="">
        </div>
    </header>
</template>

<script>
export default {
    name: "HeaderComponent",

    computed: {
        settings() {
            return this.$store.state.settings.items
        }
    }
}
</script>

<style scoped>
header {
    width: 100%;
    height: 100px;
    padding: 15px 0;
    background-color: var(--primary-color-hexa);
}

header img {
    height: auto;
    display: block;
    max-width: 250px;
}
</style>