<template>
    <footer>
        <div class="container">
            Todos os direitos reservados <strong>{{settings.company_name}}</strong>

            <div class="float-end">
                <a href="https://taggold.com.br" target="_blank">
                    <img width="90" src="@/assets/logo-white.png" alt="">
                </a>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "FooterComponent",

    computed: {
        settings() {
            return this.$store.state.settings.items
        }
    }
}
</script>

<style scoped>
footer {
    left: 0;
    bottom: 0;
    width: 100%;
    color: #ffffff;
    position: fixed;
    padding: 15px 0;
    background-color: #262626;
}
</style>