import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";

// Toast Notification
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css"

// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

// FontAwesome Icons
import "@/assets/icons/fontawesome.css"

// CSS
import "@/assets/css/main.css"
import "@vueform/multiselect/themes/default.css"

import VueLazyload from 'vue-lazyload'
const loadImage = "https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExa21lYnE2cGVxeGc4dzkyZmJ4cm96NmFkbDJ5ZzZzYjNodmtnMXl2aCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3oEjI6SIIHBdRxXI40/giphy.gif"


if (process.env.NODE_ENV === 'production')
    axios.defaults.baseURL = 'https://system.taggold.com.br/'
else
    axios.defaults.baseURL = 'https://taggold.d7mmarketing.com.br/'

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['hostName'] = location.host;

createApp(App)
    .use(Toast)
    .use(store)
    .use(router)
    .use(VueLazyload, {
        loading: loadImage
    })
    .mount('#app')
