<template>
    <vue-easy-lightbox :visible="visibleRef" :imgs="formData.photosModal" :index="indexRef" @hide="onHide"/>

    <painel-component>
        <p>Bem vindo ao sistema, <strong>{{ formData.title }}</strong></p>
        <hr>

        <row-component class="clearfix" v-if="formData.baths.length === 0">
            <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <button-submit title="Imprimir" class="float-end" @click="print" icon="fa-solid fa-print"/>
                <!-- <button-route title="Pedidos" class="float-end iwassa-bg mx-1" @click="routeBathRequest" :items="itemsOrder"/> -->
                <button title="Pedidos" class="float-end iwassa-bg mx-1" @click="routeBathRequest" :items="itemsOrder">
                    <i class="fa-solid fa-check-to-slot"></i>
                    <span>
                        Pedidos
                    </span>
                </button>
            </row-col-component>
        </row-component>

        <div class="alert alert-warning" role="alert" v-if="formData.baths.length === 0">
            Nenhuma peça disponível.
        </div>

        <row-component class="justify-content-between" v-else>
            <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                <h5 class="text-uppercase">Estoque</h5>
            </row-col-component>

            <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                <button-submit title="Imprimir" class="float-end" @click="print" icon="fa-solid fa-print"/>
                <button-route title="Pedidos" class="float-end iwassa-bg mx-1" @click="routeBathRequest" :items="itemsOrder"/>
            </row-col-component>
        </row-component>

        <row-component>
            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                               v-for="(data, index) in formData.baths" :key="index">
                <div class="card" style="width: 100%;">
                    <div class="btn-avatar-view">
                        <button-submit @click="showImg(index)" icon="fa-solid fa-camera-retro"/>
                    </div>
                    <img @click="showImg(index)" v-lazy="data.photo" class="img-client" alt="...">
                    <div class="card-body">
                        <h6 class="card-title">Grupo de banho: {{ data.group_name }}</h6>
                        <ul class="list-group list-group-flush mb-4">
                            <li class="list-group-item">Quantidade: {{ data.the_amount }}</li>
                            <li class="list-group-item">Peso unitário: {{ data.kilo }}</li>
                            <li class="list-group-item">Peso total: {{ data.peso_total }}</li>
                            <li class="list-group-item">Valor bruto: {{ data.price_bruto }}</li>
                            <li class="list-group-item">Código: {{ data.code }}</li>
                            <li class="list-group-item">Origem: {{ data.origem }}</li>
                        </ul>
                    </div>
                </div>
            </row-col-component>
        </row-component>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/rows/RowComponent";
import RowColComponent from "@/components/rows/RowColComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import VueEasyLightbox from "vue-easy-lightbox";
import {useToast} from "vue-toastification";
import {ref} from "vue";
import ButtonRoute from "@/components/buttons/ButtonRoute.vue";

const toast = useToast();
export default {
    name: "StockClient",
    components: {
        ButtonRoute,
        VueEasyLightbox,
        ButtonSubmit,
        PainelComponent,
        RowColComponent,
        RowComponent,
    },

    props: {
        hash: {
            required: true,
        }
    },

    created() {
        this.loadItems()
    },

    setup() {
        const visibleRef = ref(false)
        const indexRef = ref(0)
        const imgs = []
        const showImg = (index) => {
            indexRef.value = index
            visibleRef.value = true
        }
        const onHide = () => visibleRef.value = false
        return {
            visibleRef,
            indexRef,
            imgs,
            showImg,
            onHide
        }
    },

    data() {
        return {
            itemsOrder: {
                name: 'Pedidos',
                route: '/',
                btn: 'btn-primary',
                icon: 'fa-check-to-slot',
            },

            formData: {
                id: "",
                client_id: "",
                location_id: "",
                code: "",
                code_url: "",
                edit: "",
                baths: [],
                title: "",
                printUrl: "",
                photosModal:[]
            },
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadStockClient', this.hash)
                .then((response) => {
                    this.formData = response
                }).catch(() => {
                toast.error('Erro desconhecido!', {timeout: 1500})
            })
        },

        submit() {
            this.$store.dispatch('ToApproveOs', this.formData)
                .then(() => {
                    toast.success('OS Aprovada com sucesso', {timeout: 1500})
                }).catch(() => {
                toast.error('Erro ao aprovar a OS', {timeout: 1500})
            })
        },

        print() {
            window.open(this.formData.printUrl, "_blank")
        },

        routeBathRequest() {
            this.$router.push({name: "stock-client-orders", params: {hash: this.hash, id: this.formData.id}})
        },
    },
}
</script>

<style scoped>
.img-client {
    display: block;
    margin-right: auto;
    margin-left: auto;
    width: auto;
    max-height: 270px;
}

.iwassa-bg {
    border-color: var(--primary-color-hexa);
    background-color: var(--primary-color-hexa);
}

.iwassa-bg:active,
.iwassa-bg:focus,
.iwassa-bg:hover {
    border-color: var(--primary-color-hexa) !important;
    background-color: var(--primary-color-hexa) !important;
}

.btn-avatar-view {
    margin: 0;
    opacity: 0;
    width: 150px;
    z-index: 100;
    height: 200px;
    display: grid;
    position: absolute;
    place-items: center;
    left: 50%;
    top: 100px;
    transform: translate(-50%, -50%);
    transition: all 0.2s ease-in-out;
    background-color: rgba(0, 0, 0, 0.3);
}

.btn-avatar-view:hover {
    opacity: 1;
}
</style>
