<template>
    <vue-easy-lightbox :visible="visibleRef" :imgs="baths.photosModal" :index="indexRef" @hide="onHide"/>

    <painel-component>
        <error-component v-if="showError" :errors="errors"/>

        <form>
            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>OS:</label>
                    <input-component v-model="formData.os" disabled/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Grupo de Banho:</label>
                    <Multiselect v-model="formData.group_id" :options="multiselectBathGroups" :searchable="true"
                                 @select="handleClick" @deselect="handleClick" @clear="handleClick" @close="handleClick"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"
                                 mode="tags" :close-on-select="false" :create-option="true" :groups="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Porcentagem %:</label>
                    <input-component v-model="formData.percentage" type="number"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                    <label>Serviço:</label>
                    <Multiselect v-model="formData.service_id" :options="multiselectService" :searchable="true"
                                 @select="loadServicePrice(formData.service_id)"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                    <label>Milésimos:</label>
                    <input-component :disabled="show_ml" v-money="money3" v-model="formData.layer"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                    <label>M.O.:</label>
                    <input-component disabled maxlength="4" v-money="money3" v-model="formData.thousandth"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Base:</label>
                    <Multiselect v-model="formData.base_id" :options="multiselectServiceBase" :searchable="true"
                                 @select="loadServicePriceAdditional(formData.base_id, 1)"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Cor:</label>
                    <Multiselect v-model="formData.cor_id" :options="multiselectServiceColor" :searchable="true"
                                 @select="loadServicePriceAdditional(formData.cor_id, 2)"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Verniz:</label>
                    <Multiselect v-model="formData.verniz_id" :options="multiselectServiceVarnish" :searchable="true"
                                 @select="loadServicePriceAdditional(formData.verniz_id, 3)"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Preço do Serviço:</label>
                    <input-component v-model="formData.price_kilo" v-money="MONEY"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Preço Base:</label>
                    <input-component v-model="formData.price_base" v-money="MONEY"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Preço Cor:</label>
                    <input-component v-model="formData.price_cor" v-money="MONEY"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Preço Verniz:</label>
                    <input-component v-model="formData.price_verniz" v-money="MONEY"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit @click="search" :disabled="sendForm" class="w-100" icon="fa-magnifying-glass"
                                   name="Buscar"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsOne"/>
                </row-col-component>
            </row-component>

            <template v-for="(data, index) in baths.baths" :key="index">
                <div class="card bg-light-subtle p-3 mb-4 position-relative">
                    <div class="count">
                        {{ index + 1 }}
                    </div>

                    <div class="mb-2 clearfix">
                        <button type="button" @click="deleteBath(index)" class="float-end btn btn-danger">
                            <i class="fa-solid fa-trash"></i>
                        </button>
                    </div>

                    <div class="img-client position-relative">
                        <div class="btn-avatar-view">
                            <button-submit @click="showImg(index)" icon="fa-solid fa-camera-retro"/>
                        </div>
                        <img @click="showImg(index)" :src="data.photo" class="img-client" alt="...">
                    </div>

                    <row-component>
                        <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                            <label>Grupo de Banho:</label>
                            <Multiselect disabled :options="multiselectServiceBathGroup" v-model="data.bath_group_id"/>
                        </row-col-component>

                        <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                            <label>Origem:</label>
                            <input-component v-model="data.origem" disabled/>
                        </row-col-component>

                        <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                            <label>Código:</label>
                            <input-component v-model="data.code" disabled/>
                        </row-col-component>

                        <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                            <label>Preço Bruto UN:</label>
                            <input-component v-model="data.price_bruto" disabled/>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                            <label>Quantidade Original:</label>
                            <input-component v-model="data.the_amount_original" disabled/>
                        </row-col-component>

                        <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                            <label>Quantidade Disponíveis:</label>
                            <input-component v-model="data.available_quantity" disabled/>
                        </row-col-component>

                        <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                            <label>Quantidade Banho:</label>
                            <input-component v-model="data.the_amount" disabled/>
                        </row-col-component>

                        <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                            <label>Peso:</label>
                            <input-component type="text" v-model="data.kilo" disabled/>
                        </row-col-component>

                        <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                            <label>Total Peso:</label>
                            <input-component type="text" v-model="data.kilo_total" disabled/>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                            <label>Preço Base:</label>
                            <input-component v-model="data.price_base" disabled/>
                        </row-col-component>

                        <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                            <label>Preço Total Base:</label>
                            <input-component v-model="data.price_base_total" disabled/>
                        </row-col-component>

                        <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                            <label>Preço Cor:</label>
                            <input-component v-model="data.price_cor" disabled/>
                        </row-col-component>

                        <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                            <label>Preço Total Cor:</label>
                            <input-component v-model="data.price_cor_total" disabled/>
                        </row-col-component>

                        <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                            <label>Preço Verniz:</label>
                            <input-component v-model="data.price_verniz" disabled/>
                        </row-col-component>

                        <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                            <label>Preço Total Verniz:</label>
                            <input-component v-model="data.price_verniz_total" disabled/>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                            <label>Preço do Serviço:</label>
                            <input-component v-model="data.price_kilo" disabled/>
                        </row-col-component>

                        <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                            <label>Custo do Banho Por KG:</label>
                            <input-component v-model="data.price_bath_kg" disabled/>
                        </row-col-component>

                        <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                            <label>Custo Do Serviço (ML + MO):</label>
                            <input-component v-model="data.price_ml_mo" disabled/>
                        </row-col-component>

                        <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                            <label>Subtotal:</label>
                            <input-component v-model="data.price_subtotal" disabled/>
                        </row-col-component>
                    </row-component>
                </div>
            </template>

            <row-component v-if="baths.baths">
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button-submit class="w-100" :disabled="sendForm" @click="submit" icon="fa-circle-plus"
                                   name="Adicionar"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import RowComponent from "@/components/rows/RowComponent";
import RowColComponent from "@/components/rows/RowColComponent";
import InputComponent from "@/components/forms/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import VueEasyLightbox from "vue-easy-lightbox";

import {VMoney} from "v-money";
import {MONEY} from "@/configs/constants";
import {useToast} from "vue-toastification";
import {ref} from "vue";

const toast = useToast()
export default {
    name: "BathGroupCreate",
    components: {
        VueEasyLightbox,
        ButtonRoute,
        ButtonSubmit,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        ErrorComponent,
        PainelComponent
    },

    directives: {
        money: VMoney,
    },

    props: {
        id: {
            required: true,
        },

        hash: {
            required: true,
        }
    },

    created() {
        this.loadItems()
        this.$store.dispatch('loadMultiselectService', this.hash)
        this.$store.dispatch('loadMultiselectServiceBase', this.hash)
        this.$store.dispatch('loadMultiselectServiceColor', this.hash)
        this.$store.dispatch('loadMultiselectServiceVarnish', this.hash)
    },

    setup() {
        const visibleRef = ref(false)
        const indexRef = ref(0)
        const imgs = []
        const showImg = (index) => {
            indexRef.value = index
            visibleRef.value = true
        }
        const onHide = () => visibleRef.value = false
        return {
            visibleRef,
            indexRef,
            imgs,
            showImg,
            onHide
        }
    },

    data() {
        return {
            itemsOne: {
                route: `/hash/${this.hash}`,
                name: 'Voltar',
                icon: 'fa-rotate-left',
            },

            formData: {
                os: '',
                table: '',
                order_service_id: this.id,
                group_id: [],
                percentage: 50,
                service_id: '',
                layer: '',
                thousandth: '',
                base_id: '',
                cor_id: '',
                verniz_id: '',
                price_kilo: '',
                price_base: '',
                price_cor: '',
                price_verniz: '',
            },

            baths: [],
            bathsArr: [],

            MONEY,
            money3: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '',
                precision: 1,
                allowBlank: false,
                max: 99,
                minimumNumberOfCharacters: 3,
                masked: true
            },

            show_ml: false,

            errors: [],
            sendForm: false,
            showError: false
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch("loadGetGroupsBaths", this.hash)
                .then((response) => {
                    this.formData.os = response.os
                    this.formData.table = response.table
                    this.changeBathArray()
                }).catch(() => {
                toast.error("Erro desconhecido", {timeout: 1500})
            })
        },

        search() {
            this.formData.hash = this.hash
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('loadGroupsBaths', this.formData)
                    .then((response) => {
                        this.baths = response
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                    }).catch((e) => {
                    this.showError = true
                    this.sendForm = false
                    this.errors = e.response.data.errors
                })
            }
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.baths['hash'] = this.hash
                this.$store.dispatch('storeGroupBath', this.baths)
                    .then(() => {
                        this.errors = []
                        this.sendForm = false
                        this.showError = false
                        this.$router.push({name: 'home', params: {hash: this.hash}})
                        toast.success('Cadastro efetuado com sucesso', {timeout: 1500})
                    }).catch((e) => {
                    this.showError = true
                    this.sendForm = false
                    this.errors = e.response.data.errors
                })
            }
        },

        loadServicePrice(id) {
            let params = {hash: this.hash, id: id}
            this.$store.dispatch('loadMultiselectServicePrice', params)
                .then((r) => {
                    this.formData.price_kilo = r.price
                    this.formData.thousandth = r.mo_value
                    this.formData.layer = r.ml_value
                    this.formData.base_id = r.base
                    this.formData.cor_id = r.cor
                    this.formData.verniz_id = r.verniz
                    this.formData.price_base = r.price_base
                    this.formData.price_cor = r.price_cor
                    this.formData.price_verniz = r.price_verniz
                    if (parseInt(r.show_ml) === 2) this.show_ml = false
                    if (parseInt(r.show_ml) === 1) this.show_ml = true
                })
        },

        loadServicePriceAdditional(id, type) {
            let params = {hash: this.hash, id: id}
            this.$store.dispatch('loadMultiselectServicePriceAdditional', params)
                .then((r) => {
                    if (type === 1) {
                        this.formData.price_base = r
                    }
                    if (type === 2) {
                        this.formData.price_cor = r
                    }
                    if (type === 3) {
                        this.formData.price_verniz = r
                    }
                })
        },

        deleteBath(index) {
            this.baths.splice(index, 1)
        },

        changeBathArray() {
            this.$store.dispatch('loadMultiselectServiceBathGroup', this.hash)
                .then((response) => {
                    this.bathsArr.push({
                        label: "Selecionar tudo",
                        options: response
                    })
                })
        },

        handleClick() {
            const value = [...this.formData.group_id]
            const group = this.multiselectBathGroups[0]

            if (value) {
                const allSelected = value.length === group.options.length;
                group.label = allSelected ? "Deselecionar todos" : "Selecionar todos";
            } else {
                group.label = "Selecionar todos"
            }
        },
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        multiselectService() {
            return this.$store.state.var.multiselectService
        },

        multiselectServiceBase() {
            return this.$store.state.var.multiselectServiceBase
        },

        multiselectServiceColor() {
            return this.$store.state.var.multiselectServiceColor
        },

        multiselectServiceVarnish() {
            return this.$store.state.var.multiselectServiceVarnish
        },

        multiselectServiceBathGroup() {
            return this.$store.state.var.multiselectServiceBathGroup
        },

        multiselectBathGroups() {
            return this.bathsArr
        },
    }
}
</script>

<style scoped>
.count {
    height: 35px;
    width: 90px;
    padding: 5px;
    color: #FFFFFF;
    position: absolute;
    background: var(--primary-color-hexa);
}

.img-client {
    width: 150px;
    display: block;
    max-height: 200px;
    margin-right: auto;
}

.btn-avatar-view {
    margin: 0;
    opacity: 0;
    width: 150px;
    z-index: 100;
    height: 200px;
    display: grid;
    position: absolute;
    place-items: center;
    left: 50%;
    top: 100px;
    transform: translate(-50%, -50%);
    transition: all 0.2s ease-in-out;
    background-color: rgba(0, 0, 0, 0.3);
}

.btn-avatar-view:hover {
    opacity: 1;
}
</style>