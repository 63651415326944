const money = {
    decimal: ',',
    thousands: '.',
    prefix: '',
    suffix: '',
    precision: 2,
    masked: false /* doesn't work with directive */
}

export const URL_BASE = '/api/v1/'

export const MONEY = money