<template>
    <div class="body">
        <div class="login-page">
            <div class="imgLogin">
                <img src="@/assets/logo-white.png" alt="">
            </div>

            <div class="form">
                <form class="login-form">
                    <div class="d-flex align-items-center justify-content-between mb-3">
                        <p>Cadastre-se</p>

                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="docs" id="cpf">
                            <label class="form-check-label" for="cpf">CPF</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="docs" id="cnpj" checked>
                            <label class="form-check-label" for="cnpj">CNPJ</label>
                        </div>
                    </div>

                    <input class="input" v-model="value" placeholder="Email" maxlength="20" type="email">
                    <input class="input" v-model="value" placeholder="Senha" maxlength="20" type="password">
                    <input class="input" v-model="value" placeholder="Confirmar senha" maxlength="20" type="password">
                    <button :disabled="sendForm" type="submit">
                        Entrar
                    </button>
                </form>
                <div class="d-flex justify-content-between mt-3">
                    <a href="/login">Voltar</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RecoverPassword',

    data() {
        return {
            formData: {
                email: '',
                password: ''
            },

            sendForm: false
        }
    },

    methods: {}
}
</script>

<style scoped>
@import url(https://fonts.googleapis.com/css?family=Roboto:300);

.body {
    width: 100vw;
    height: 100vh;
    font-family: "Roboto", sans-serif;
    background-color: var(--blue-main);
}

.login-page {
    margin: auto;
    width: 450px;
    padding: 8% 0 0;
}

.form {
    z-index: 1;
    padding: 45px;
    position: relative;
    text-align: center;
    background: #FFFFFF;
    margin: 0 auto 100px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.form p {
    padding: 0;
    margin: 10px 0;
    text-align: left;
    text-transform: uppercase;
}

.form input.input {
    border: 0;
    outline: 0;
    width: 100%;
    padding: 15px;
    font-size: 14px;
    margin: 0 0 15px;
    background: #f2f2f2;
    box-sizing: border-box;
}

.form button {
    border: 0;
    outline: 0;
    width: 100%;
    padding: 15px;
    color: #FFFFFF;
    font-size: 14px;
    cursor: pointer;
    text-transform: uppercase;
    transition: all 0.3s ease;
    background: var(--yellow-main);
    -webkit-transition: all 0.3s ease;
}

.form button:hover,
.form button:active,
.form button:focus {
    background-color: var(--yellow-main);
}

.form a {
    width: 100%;
    padding: 15px;
    color: #000000;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    background-color: #eeeeee;
}

.login-page .imgLogin img {
    display: block;
    margin: 0 auto;
    max-height: 100px;
}

.login-page .imgLogin {
    padding: 5% 0;
    background-color: var(--yellow-main);
}

@media (max-width: 767px) {
    .login-page {
        width: 96%;
    }

    .login-page img {
        max-width: 200px;
    }

    .form {
        padding: 20px;
    }
}
</style>