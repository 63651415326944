<template>
    <loader-component/>
    <router-view/>
</template>

<script>
import LoaderComponent from "@/components/alerts/LoaderComponent";

export default {
    name: "MainComponent",
    components: {
        LoaderComponent
    },

    props: {
        hash: {
            required: true
        }
    },

    created() {
        this.loadSettings()
    },

    data() {
        return {
            root: document.documentElement
        }
    },

    methods: {
        loadSettings() {
            this.$store.dispatch("loadSettings", this.hash)
                .then(() => {
                    this.root.style.setProperty("--primary-color-hexa", `${this.settings.primary_color_hexa}`);
                })
        }
    },

    computed: {
        settings() {
            return this.$store.state.settings.items
        }
    }
}
</script>