<template>
    <button :type="type" class="btn iwassa-bg" :class="btn" @click="submit">
        <i class="fa-solid" :class="icon"></i>
        {{ name }}
    </button>
</template>

<script>
export default {
    name: 'ButtonSubmit',
    props: {
        name: {
            type: String,
            default: ''
        },

        type: {
            type: String,
            default: 'button'
        },

        btn: {
            type: String,
            default: 'btn-primary'
        },

        icon: {
            type: String,
            default: ''
        }
    },

    methods: {
        submit() {
            this.$emit('submit')
        }
    }
}
</script>

<style scoped>
.iwassa-bg {
    border-color: var(--primary-color-hexa);
    background-color: var(--primary-color-hexa);
}

.iwassa-bg.disabled,
.iwassa-bg:disabled{
    opacity: 0.8;
    border-color: var(--primary-color-hexa) !important;
    background-color: var(--primary-color-hexa) !important;
}

.iwassa-bg:active,
.iwassa-bg:focus,
.iwassa-bg:hover {
    border-color: var(--primary-color-hexa) !important;
    background-color: var(--primary-color-hexa) !important;
}
</style>