export default {
    noOptionsText: 'Nenhuma opção encontrada',
    noResultText: 'Nenhum resultado encontrado',

    multiselectStatusOS: [
        {value: 1, label: "Cadastrado"},
        {value: 2, label: "Enviado Para Cliente"},
        {value: 3, label: "Aguardando Aprovação Iwassa"},
        {value: 4, label: "Separação"},
        {value: 5, label: "Amarração"},
        {value: 6, label: "Banho"},
        {value: 7, label: "Inspeção"},
        {value: 8, label: "Retornou para banho"},
        {value: 9, label: "Envio"},
        {value: 10, label: "Finalizado"}
    ],

    storage: null,
    multiselectService: [],
    multiselectServiceBase: [],
    multiselectServiceColor: [],
    multiselectServiceVarnish: [],
    multiselectServiceBathGroup: [],
}