import axios from "axios";
import {URL_BASE} from "@/configs/constants";

const RESOURCE = "client/hash";


export default {
    actions: {
        loadStockClient(context, hash) {
            context.commit("LOADER", true)
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}${RESOURCE}/${hash}/stock-client`)
                    .then((response) => {
                        return resolve(response.data)
                    }).catch((errors) => {
                    return reject(errors)
                }).finally(() => context.commit("LOADER", false))
            })
        },

        loadStockClientGetOrders(context, params) {
            context.commit("LOADER", true)
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}${RESOURCE}/${params.hash}/stock-client-get-orders`, {params})
                    .then((response) => {
                        context.commit("STOCK_CLIENT_GET_ORDERS", response.data)
                        return resolve(response.data)
                    }).catch((errors) => {
                    return reject(errors)
                }).finally(() => context.commit("LOADER", false))
            })
        }
    },

    mutations:{
        STOCK_CLIENT_GET_ORDERS(state, stock_client_get_orders){
            state.items = stock_client_get_orders
        }
    },

    state:{
        items: {
            data: []
        }
    }
}