<template>
    <router-link role="button" :to="items.route" class="btn btn-secondary" :class="items.btn">
        <i class="fa-solid" :class="items.icon"></i>
        {{ items.name }}
    </router-link>
</template>

<script>
export default {
    name: 'ButtonRoute',
    props: {
        items: {
            type: Object,
        },
    }
}
</script>