import axios from "axios";
import {URL_BASE} from "@/configs/constants";

const RESOURCE = "client/hash";

export default {

    loadOsBaths(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/${formData.hash}/get-os/${formData.id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    storeOsBath(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}${RESOURCE}/${formData.hash}/add-service-bath`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    loadOSBath(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/${formData.hash}/get-os/${formData.id}/${formData.id_service}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    updateOsBath(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}${RESOURCE}/${formData.hash}/update-service-bath`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },
}