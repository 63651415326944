<template>
    <header-component/>

    <div class="body-sys container mt-5 py-5 px-3 px-lg-5">
        <slot></slot>
    </div>

    <footer-component/>
</template>

<script>
import HeaderComponent from "@/components/dashboard/HeaderComponent";
import FooterComponent from "@/components/dashboard/FooterComponent";

export default {
    name: "PainelComponent",
    components: {
        FooterComponent,
        HeaderComponent
    }
}
</script>

<style scoped>
.body-sys {
    margin-bottom: 100px;
    -webkit-box-shadow: 9px 17px 31px 0 rgba(150, 150, 150, 1);
    -moz-box-shadow: 9px 17px 31px 0 rgba(150, 150, 150, 1);
    box-shadow: 9px 17px 31px 0 rgba(150, 150, 150, 1);
}
</style>