import {createRouter, createWebHistory} from 'vue-router'

import HomeView from '@/views/HomeView'
import LoginView from "@/views/LoginView";
import RegisterClient from "@/views/RegisterClient";
import RecoverPassword from "@/views/RecoverPassword";
import MainComponent from "@/components/dashboard/MainComponent";

import BathCreate from "@/views/create/BathCreate";
import BathUpdate from "@/views/update/BathUpdate";

import StockClient from "@/views/StockClient";

import BathGroupCreate from "@/views/create/BathGroupCreate";

import BathRequestCreate from "@/views/create/BathRequestCreate";
import StockClientOrders from "@/views/StockClientOrders.vue";

const routes = [
    {
        path: '/',
        component: MainComponent,
        props: true,
        children: [
            {path: '/', name: 'login', component: LoginView},
            {path: 'register-client', name: 'register-client', component: RegisterClient},
            {path: 'recover-password', name: 'recover-password', component: RecoverPassword},
            {path: 'hash/:hash', name: 'home', component: HomeView, props: true},

            {path: 'create/:hash/:id/:id_bath', name: 'os-bath-create', component: BathCreate, props: true},
            {path: 'edit/:hash/:id/:id_service', name: 'os-bath-edit', component: BathUpdate, props: true},

            {path: 'hash/:hash/stock-client', name: 'stock-client', component: StockClient, props: true},
            {path: 'hash/:hash/:id/stock-client-orders', name: 'stock-client-orders', component: StockClientOrders, props: true},

            {path: 'hash/:hash/:id/bath-group', name: 'bath-group-create', component: BathGroupCreate, props: true},

            {path: 'hash/:hash/:id/bath-request', name: 'bath-request-create', component: BathRequestCreate, props: true},
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
