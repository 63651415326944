import axios from "axios";
import {URL_BASE} from "@/configs/constants";

const RESOURCE = "client/hash";

export default {
    actions: {
        loadSettings(context, hash) {
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}${RESOURCE}/${hash}/settings-get`)
                    .then((response) => {
                        context.commit("LOAD_SETTINGS", response.data)
                        return resolve(response.data)
                    }).catch((errors) => {
                    return reject(errors)
                })
            })
        }
    },

    mutations: {
        LOAD_SETTINGS(state, settings) {
            state.items = settings
        }
    },

    state: {
        items: {
            data: []
        }
    }
}